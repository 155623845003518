<template>
  <DialogContainer
    :isOpen="isOpen"
    :onClose="closeModal"
    :maxWidth="'3xl'"
    :maxHeight="'4xl !important'"
  >
    <template v-slot:content>
      <div class="flex">
        <div>
          <h3 class="text-lg leading-6 font-medium text-gray-900 invisible">
            Upload List
          </h3>
          <div class="w-60 rounded-md hover:bg-white">
            <div
              v-bind:class="{ 'bg-gray-100': !triggerGR && isMyComputer }"
              @click="openMyComputer"
              class="w-52 rounded-md py-2 cursor-pointer hover:bg-gray-100"
            >
              <button
                type="button"
                :disabled="isKlaviyoImporting"
                class="inline-flex items-left px-4 py-2 text-sm font-medium text-black"
                v-bind:class="{ 'cursor-not-allowed': isKlaviyoImporting }"
              >
                <div
                  class="rounded-full bg-red-400 border w-6 h-6 justify-center relative"
                >
                  <DesktopComputerIcon
                    class="w-5 h-5 text-white bg-transparent absolute pl-0.5 pt-0.5"
                  />
                </div>
                &nbsp; My Computer
              </button>
            </div>
          </div>
          <div class="w-60 rounded-md hover:bg-white">
            <div
            v-bind:class="{ 'bg-gray-100': triggerGR }"
              class="w-52 rounded-md py-2 cursor-pointer hover:bg-gray-100"
              @click="openGR"
            >
              <button
                type="button"                
                class="inline-flex items-left px-4 py-2 text-sm font-medium text-black"
              >
                <div class="rounded-full bg-blue-400 w-6 h-6 relative">
                  <img
                    class="w-5 h-5 text-white absolute pl-1 pt-1"
                    :src="require('@/assets/images/get_response_logo.png')"
                    alt=""
                  />
                </div>
                &nbsp; GetResponse
              </button>
            </div>
          </div>

          <div class="w-60 rounded-md hover:bg-white">
            <div
            v-bind:class="{ 'bg-gray-100': isYotpo && !triggerGR }"
              class="w-52 rounded-md py-2 cursor-pointer hover:bg-gray-100"
              @click="openYotpo"
            >
              <button
                type="button"
                class="inline-flex items-left px-4 py-2 text-sm font-medium text-black"
              >
                <div class="rounded-full bg-blue-700 w-6 h-6 relative">
                  <img
                    class="w-5 h-5 text-white absolute pl-1 pt-1"
                    :src="require('@/assets/images/logo_yotpo.png')"
                    alt=""
                  />
                </div>
                &nbsp; Yotpo
              </button>
            </div>
          </div>

          <div class="w-60 rounded-md hover:bg-white">
            <div
            v-bind:class="{ 'bg-gray-100': isIterable && !triggerGR }"
              class="w-52 rounded-md py-2 cursor-pointer hover:bg-gray-100"
              @click="openIterable"
            >
              <button
                type="button"
                class="inline-flex items-left px-4 py-2 text-sm font-medium text-black"
              >
                <div class="rounded-full bg-gray-100 w-6 h-6 relative">
                  <img
                    class="w-5 h-5 text-white absolute pl-1 pt-1"
                    :src="require('@/assets/images/iterable_logo.png')"
                    alt=""
                  />
                </div>
                &nbsp; Iterable
              </button>
            </div>
          </div>
        </div>
          <div v-if="isMyComputer && !isKlaviyo && !isKlaviyoList && !isYotpo">
            <h3 class="text-lg leading-6 font-medium text-gray-900">
              Upload List
            </h3>
            <div class="mt-2">
              <ol class="text-sm text-gray-500 list-inside list-decimal">
                <li>Upload your list in CSV (.csv) format</li>
                <li>
                  You may include unlimited columns but all email addresses must
                  be in one column
                </li>
                <li>Drop file or click below to upload</li>
              </ol>
            </div>
            <input
              ref="fileInput"
              type="file"
              v-on:change="onFileInputChange"
              accept=".csv,text/csv"
              class="hidden"
            />
            <button
              :class="dragAreaClass"
              @dragover="dragOver"
              @dragleave="dragLeave"
              @drop="drop"
              @click="openFileBrowser"
            >
              <PulseLoader v-if="isUploading" color="#ff5a5f" />
              <div v-else-if="files.length > 0">
                <ul v-for="file in files" :key="file.name">
                  <li>{{ file.name }}</li>
                </ul>
              </div>
              <div v-else>
                <UploadIcon class="h-12 mx-auto mb-2" />
                Click or drop file here
              </div>
            </button>
          </div>

          <div
            class="P-5 pl-1"
            v-if="isKlaviyo && !isKlaviyoList && !isMyComputer && !isYotpo"
          >
            <h3 class="text-lg leading-6 font-medium text-gray-900">
              Connect to Klaviyo
            </h3>
            <div class="text-sm text-gray-600 list-inside list-decimal pt-3">
              Before importing your list, you'll need to create a new Klaviyo API
              key and share it with us.
            </div>
            <div class="text-sm text-gray-600 list-inside list-decimal">
              <br />
              <div class="text-black pb-3 font-medium">Instructions</div>
              <div class="pl-1">
                1. In Klaviyo, go to
                <span
                  ><a
                    class="link-text cursor-pointer"
                    target="_blank"
                    href="https://www.klaviyo.com/account#api-keys-tab"
                    >Accounts>Settings>API Keys</a
                  ></span
                >
                <br /><br />
                2. Click <span class="font-bold">Create Private API Key</span
                ><br /><br />
                3. Label it as Alfred (optional)<br /><br />
                4. Copy and paste API key below to get started.<br /><br />
              </div>
              <div>
                <div class="text-black pb-1 font-medium">API Key</div>
                <div class="pt-2 pb-1">
                  <input
                    class="form-field-no-icon pr-5 w-full"
                    id="apikey"
                    @input="submitApiKey"
                    autocomplete="off"
                  />
                </div>
              </div>
            </div>
          </div>

          <div
            class="P-5 pl-1"
            v-if="isKlaviyoList && !isKlaviyo && !isMyComputer && !isYotpo"
          >
            <h3 class="text-lg leading-6 font-medium text-gray-900 pb-2">
              Choose list to import emails from Klaviyo
            </h3>

            <div class="p-5 w-full" v-if="isKlaviyoImporting">
              <div
                class="sm:flex sm:items-start justify-center"
                v-if="isUploading"
              >
                <PulseLoader color="#ff5a5f" />
              </div>
              <div v-if="!isUploading">
                <div class="bg-red-100 rounded-full">
                  <div
                    color="#ff5a5f"
                    class="bg-red-500 text-xs font-medium text-red-100 text-center p-0.5 leading-none rounded-l-full"
                    :style="`width:${progress}%`"
                  >
                    {{ progress }}%
                  </div>
                </div>
                <div
                  class="flex sm:w-30 w-full items-end justify-items-end mt-1 flex-row-reverse pt-2 border-red-50"
                >
                  <button class="button-cancel" @click="cancelKlaviyoImport">
                    Cancel
                  </button>
                </div>
              </div>
            </div>
            <div v-if="!isKlaviyoImporting">
              <div class="flex sm:w-30 items-start justify-items-start mt-2">
                <SearchInput
                  v-model="searchKeyword"
                  @input="searchList"
                  placeholder="Search by List"
                />
              </div>
              <div
                class="text-xs text-gray-600 list-inside list-decimal invisible"
              >
                Before importing your list, you'll need to create a new Klaviyo
                API key and import date from
              </div>

              <div
                class="h-48 overflow-auto overflow-x-hidden scroll-style w-full"
              >
                <table class="w-full">
                  <tbody>
                    <tr
                      class="border-b"
                      v-for="(list, list_id) in klaviyoList"
                      :key="list_id"
                    >
                      <td
                        class="px-6 py-3 whitespace-nowrap text-sm font-medium text-gray-900 max-w-sm truncate"
                      >
                        <input
                          type="radio"
                          :checked="selection"
                          :id="list.list_id"
                          :name="list.list_name"
                          @change="onSelectList($event)"
                          :value="list.list_id"
                        />
                        <span
                          class="text-sm text-black px-6 py-3 text-center max-w-sm truncate"
                          :for="list.list_id"
                          >{{ list.list_name }}</span
                        >
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="flex pt-4">
                <div>
                  <label class="text-sm items-center justify-center pt-2 pl-3"
                    >API Key: &nbsp;</label
                  >
                </div>
                <label class="border rounded-md p-2 border-red-300 text-sm">
                  {{ apikeyValue }}
                </label>
                <div>
                  <button class="pl-2" @click="deleteKlaviyoAPI">
                    <TrashIcon class="h-7 text-gray-600 pt-2" />
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div
            class="P-5 pl-1"
            v-if="isGR && !isGRlist && !isMyComputer && !isKlaviyo && !isKlaviyoList && !isYotpo"
          >
            <h3 class="text-lg leading-6 font-medium text-gray-900">
              Connect to GetResponse
            </h3>
            <div class="text-sm text-gray-600 list-inside list-decimal pt-3">
              Before importing your list, you'll need to create a new GetResponse API
              key and share it with us.
            </div>
            <div class="text-sm text-gray-600 list-inside list-decimal">
              <br />
              <div class="text-black pb-3 font-medium">Instructions</div>
              <div class="pl-1">
                1. In GetResponse, go to
                <span
                  ><a
                    class="link-text cursor-pointer"
                    target="_blank"
                    href="https://app.getresponse.com/api"
                    >Tools>Integrations & API>API</a
                  ></span
                >
                <br /><br />
                2. Click <span class="font-bold">Generate API Key</span
                ><br /><br />
                3. Label it as Alfred (optional)<br /><br />
                4. Copy and paste API key below to get started.<br /><br />
              </div>
              <div>
                <div class="text-black pb-1 font-medium">API Key</div>
                <div class="pt-2 pb-1">
                  <input
                    class="form-field-no-icon pr-5 w-full"
                    id="apikey"
                    @input="submitApiKey"
                    autocomplete="off"
                  />
                </div>
              </div>
            </div>
          </div>

          <div
            class="P-5 pl-1"
            v-if="isGRlist && !isGR && !isMyComputer && !isKlaviyoList && !isKlaviyo && !isYotpo"
          >
            <h3 class="text-lg leading-6 font-medium text-gray-900 pb-2">
              Choose list to import emails from GetResponse
            </h3>

            <div class="p-5 w-full" v-if="isKlaviyoImporting">
              <div
                class="sm:flex sm:items-start justify-center"
                v-if="isUploading"
              >
                <PulseLoader color="#ff5a5f" />
              </div>
              <div v-if="!isUploading">
                <div class="bg-red-100 rounded-full">
                  <div
                    color="#ff5a5f"
                    class="bg-red-500 text-xs font-medium text-red-100 text-center p-0.5 leading-none rounded-l-full"
                    :style="`width:${progress}%`"
                  >
                    {{ progress }}%
                  </div>
                </div>
                <div
                  class="flex sm:w-30 w-full items-end justify-items-end mt-1 flex-row-reverse pt-2 border-red-50"
                >
                  <button class="button-cancel" @click="cancelKlaviyoImport">
                    Cancel
                  </button>
                </div>
              </div>
            </div>
            <div v-if="!isKlaviyoImporting">
              <div class="flex sm:w-30 items-start justify-items-start mt-2">
                <SearchInput
                  v-model="searchKeyword"
                  @input="searchGRList"
                  placeholder="Search by List"
                />
              </div>
              <div
                class="text-xs text-gray-600 list-inside list-decimal invisible"
              >
                Before importing your list, you'll need to create a new GetResponse
                API key and import date from
              </div>

              <div
                class="h-48 overflow-auto overflow-x-hidden scroll-style w-full"
              >
                <table class="w-full">
                  <tbody>
                    <tr
                      class="border-b"
                      v-for="(list, list_id) in GRList"
                      :key="list_id"
                    >
                      <td
                        class="px-6 py-3 whitespace-nowrap text-sm font-medium text-gray-900 max-w-sm truncate"
                      >
                        <input
                          type="radio"
                          :checked="selection"
                          :id="list.list_id"
                          :name="list.list_name"
                          @change="onSelectList($event)"
                          :value="list.list_id"
                        />
                        <span
                          class="text-sm text-black px-6 py-3 text-center max-w-sm truncate"
                          :for="list.list_id"
                          >{{ list.list_name }}</span
                        >
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="flex pt-4">
                <div>
                  <label class="text-sm items-center justify-center pt-2 pl-3"
                    >API Key: &nbsp;</label
                  >
                </div>
                <label class="border rounded-md p-2 border-red-300 text-sm">
                  {{ apikeyValue }}
                </label>
                <div>
                  <button class="pl-2" @click="deletegrAPI">
                    <TrashIcon class="h-7 text-gray-600 pt-2" />
                  </button>
                </div>
              </div>
            </div>
          </div>  
          <YotpoIntegrationCenter v-show="isYotpo" @yotpo-clicked="handleYotpoClick" @yotpoImport="handleYotpoImport" />
          <IterableIntegrationCenter v-show="isIterable" @iterable-clicked="handleIterableClick"  @iterableImport="handleIterableImport"/>
     
      </div>
    </template>
    <template v-slot:buttons>
      <div v-if="!isYotpo" class="flex flex-row-reverse">
      <div v-if="!isIterable" class="flex flex-row-reverse">
      <div v-if="triggerGR">
        <button
        v-if="isGRlist && !isGR && !isKlaviyoList && !isKlaviyo && !isMyComputer"
        class="button-submit sm:ml-3"
        @click="getGRImport"
        :disabled="isKlaviyoImporting"
      >
        Import
      </button>
      <button
        type="button"
        v-show="!isGRlist && isGR && !isKlaviyoList && !isKlaviyo && !isMyComputer"
        class="button-submit sm:ml-3"
        :disabled="!isKlavioKey"
        @click="submitGRKey"
      >
        Submit
      </button>
    </div>
    <div v-else>
      <button
        v-if="isKlaviyoList"
        class="button-submit sm:ml-3"
        @click="getKlaviyoImport"
        :disabled="isKlaviyoImporting"
      >
        Import
      </button>

      <button
        type="button"
        :disabled="files.length === 0 || isUploading"
        class="button-submit sm:ml-3"
        v-show="!isKlavioKey && !isKlaviyoList"
        @click="submit"
      >
        Submit
      </button>
      <button
        type="button"
        v-show="isKlavioKey && !isKlaviyoList"
        :disabled="!isKlavioKey"
        class="button-submit sm:ml-3"
        @click="submitKlaviyoKey"
      >
        Submit
      </button>
    </div>
      <button
        type="button"
        :disabled="isUploading"
        class="button-cancel"
        @click="closeModal"
        v-if="!isKlaviyoImporting"
      >
        Cancel
      </button>
      </div>
    </div>
    </template>
  </DialogContainer>
</template>

<script>
import { ref } from "vue";
import {
  TrashIcon,
  UploadIcon,
  DesktopComputerIcon,
} from "@heroicons/vue/outline";
import DialogContainer from "@/components/common/DialogContainer";
import eventBus from "@/utils/eventBus";
import PulseLoader from "vue-spinner/src/PulseLoader";
import SearchInput from "@/components/common/SearchInput";
import _sortBy from "lodash/sortBy";
import YotpoIntegrationCenter from "@/components/basic/dashboard-page/YotpoIntegrationCenter";
import IterableIntegrationCenter from "@/components/basic/dashboard-page/IterableIntegrationCenter";

export default {
  name: "UploadModal",
  components: {
    DialogContainer,
    UploadIcon,
    DesktopComputerIcon,
    PulseLoader,
    TrashIcon,
    SearchInput,
    YotpoIntegrationCenter,
    IterableIntegrationCenter
  },
  setup() {
    const isOpen = ref(false);
    const isKlaviyo = ref(false);
    const isMyComputer = ref(true);
    const isDragging = ref(false);
    const files = ref([]);
    const isUploading = ref(false);
    const isKlavioKey = ref(false);
    const isKlaviyoList = ref(false);
    const klaviyoList = ref([]);
    const klaviyoContactList = ref([]);
    const apikeyValue = ref(0);
    const counter = ref(0);
    const searchKeyword = ref("");
    const klaviyoIdImport = ref(0);
    const klaviyoImportListName = ref(0);
    const radioButtons = [];
    const isKlaviyoImporting = ref(false);
    const isActive = ref(false);
    const progress = ref(0);
    const tempklaviyoList = ref([]);
    const selection = ref(false);
    const radioButton = ref(false);
    const isGR = ref(false);
    const isGRlist = ref(false);
    const GRList = ref([]);
    const triggerGR = ref(false);
    const isYotpo = ref(false);
    const isYotpoOption = ref(false);
    const isIterableOption = ref(false);
    const isIterable = ref(false);
    return {
      isOpen,
      isKlaviyo,
      isMyComputer,
      isDragging,
      files,
      isUploading,
      isKlavioKey,
      isKlaviyoList,
      klaviyoList,
      klaviyoContactList,
      apikeyValue,
      klaviyoIdImport,
      klaviyoImportListName,
      radioButtons,
      isKlaviyoImporting,
      searchKeyword,
      counter,
      isActive,
      progress,
      tempklaviyoList,
      selection,
      radioButton,
      isGR,
      isGRlist,
      GRList,
      triggerGR,   
      isYotpo,
      isYotpoOption,
      isIterable,
      isIterableOption
    };
  },
  props: {
    data: {
      type: Array,
      required: true,
    },
  },
  computed: {
    dragAreaClass() {
      const defaultClasses =
        "text-gray-400 border-dashed border-4 w-full py-16 text-center mt-5 mb-3 focus:outline-none border-gray-300";
      return this.isDragging
        ? `${defaultClasses} bg-gray-200 `
        : `${defaultClasses} bg-gray-100`;
    },
  },
  methods: {
    openModal(yotpo) {
      this.isOpen = true;
      this.isMyComputer = true;
      this.isKlaviyo = false;
      this.isKlaviyoList = false;
      this.isDragging = false;
      this.files = [];
      this.isUploading = false;
      if(yotpo){
        this.openYotpo();
      }
    },
    closeModal() {
      if (!this.isUploading && !this.isYotpoOption && !this.isIterableOption) {
        this.tempklaviyoList = [];
        this.klaviyoList = [];
        this.klaviyoContactList = [];
        this.isOpen = false;
        this.triggerGR = false;
        this.isYotpo = false;
        this.isIterable = false;
      }
    },
    dragOver(event) {
      event.preventDefault();
      this.isDragging = true;
    },
    dragLeave() {
      this.isDragging = false;
    },
    drop(event) {
      event.preventDefault();
      this.isDragging = false;
      this.$refs.fileInput.files = event.dataTransfer.files;
      if (this.validate(event.dataTransfer.files)) {
        this.files = Array.from(event.dataTransfer.files);
      }
    },
    openFileBrowser(event) {
      event.preventDefault();
      this.$refs.fileInput.click();
    },
    async openKlaviyo() {
      this.triggerGR = false;
      this.isYotpo = false;
      this.isIterable = false;
      const apikey = await this.$alfredService.klaviyoAPIKey();
      this.klaviyoIdImport = "";
      if (!apikey) {
        this.isKlaviyo = true;
        this.isKlaviyoList = false;
        this.isMyComputer = false;
        this.isUploading = false;
        this.isGR = false;
      } else {
        this.apikeyValue = apikey.api_key;
        this.callKlaviyoList();
      }    
    },
    async openYotpo() {
      this.isYotpo = true;
      this.isIterable = false;
      this.isMyComputer = false;
      this.triggerGR = false;
      this.isKlaviyo = false;
      this.isKlaviyoList = false;
    },
    async openIterable() {
      this.isIterable = true;
      this.isYotpo = false;
      this.isMyComputer = false;
      this.triggerGR = false;
      this.isKlaviyo = false;
      this.isKlaviyoList = false;
      this.isGR = false;
    },
    async openGR() {
      this.triggerGR = true;
      const apikey = await this.$alfredService.grAPIKey();
      this.klaviyoIdImport = "";
      this.isYotpo = false;
      this.isIterable = false;
      if (!apikey) {
        this.isGR = true;
        this.isGRlist = false;
        this.isMyComputer = false;
        this.isUploading = false;
        this.isKlaviyo = false;
      this.isKlaviyoList = false;
      } else {
        this.apikeyValue = apikey.api_key;
        this.callGRlist();
      }
    },
    openMyComputer() {
      this.triggerGR = false;
      this.isMyComputer = true;
      this.isUploading = false;
      this.isKlaviyo = false;
      this.isKlaviyoList = false;
      this.klaviyoIdImport = "";
      this.isGR = false;
      this.isKlavioKey = "";
      this.isYotpo = false;
      this.isIterable = false;
    },
    async deleteKlaviyoAPI() {
      const del = await this.$alfredService.klaviyoAPIKeyDelete();
      if (del) {
        this.isKlaviyo = true;
        this.isMyComputer = false;
        this.isKlaviyoList = false;
        this.apikeyValue = "";
      } else {
        this.isKlaviyo = false;
        this.isKlaviyoList = true;
        this.isMyComputer = false;
      }
    },
    async deletegrAPI() {
      const del = await this.$alfredService.grAPIKeyDelete();  
      if (del) {
        this.apikeyValue = "";
        this.openGR();
      } else {
        this.isGR = false;
        this.isGRlist = true;
        this.isMyComputer = false;
      }
    },
    async callKlaviyoList() {
      this.searchKeyword = "";
      try{
        const data = await this.$alfredService.getKlaviyoList();
        this.klaviyoList = data;
        this.klaviyoContactList = data;
        this.klaviyoList = _sortBy(this.klaviyoList, "list_name");
        this.tempklaviyoList = this.klaviyoList;
        this.isKlaviyoList = true;
        this.isKlaviyo = false;
        this.isMyComputer = false;
      }catch(e){
        this.deleteKlaviyoAPI()
      }      
    },
    async callGRlist() {
      this.searchKeyword = "";
      try{
        const data = await this.$alfredService.getGRList();
        this.GRList = data;
        this.klaviyoContactList = data;
        this.GRList = _sortBy(this.GRList, "list_name");
        this.tempklaviyoList = this.GRList;
        this.isGRlist = true;
        this.isGR = false;
        this.isMyComputer = false
        this.isKlaviyoList = false;
        this.isKlaviyo = false;
      }catch(e){
        this.deletegrAPI()
      }
    },
    submitApiKey() {
      let key = document.getElementById("apikey");
      this.apikeyValue = key.value;
      this.isKlavioKey = true;
    },
    async submitKlaviyoKey() {
      let key = document.getElementById("apikey");
      this.apikeyValue = key.value;
      if (!this.apikeyValue) {
        eventBus.trigger("notify", {
          notification: {
            group: "top-right",
            type: "warning",
            text: "Please provide a valid Klaviyo API Key.",
          },
        });
        return false;
      }
      const success = await this.$alfredService.postKlaviyoAPIKey({
        api_key: key.value,
      });
      if (success) {
        this.callKlaviyoList();
      } else {
        eventBus.trigger("notify", {
          notification: {
            group: "top-right",
            type: "warning",
            text: "Your Klaviyo API Key is not valid",
          },
        });
        return false;
      }
    },
    async submitGRKey() {
      let key = document.getElementById("apikey");
      this.apikeyValue = key.value;
      if (!this.apikeyValue) {
        eventBus.trigger("notify", {
          notification: {
            group: "top-right",
            type: "warning",
            text: "Please provide a valid GetResponse API Key.",
          },
        });
        return false;
      }
      const success = await this.$alfredService.postgrAPIKey({
        api_key: key.value,
      });
      
      if (success) {
        this.searchKeyword = "";
      const data = await this.$alfredService.getGRList();
      this.GRList = data;
      this.klaviyoContactList = data;
      this.GRList = _sortBy(this.GRList, "list_name");
      this.tempklaviyoList = this.GRList;
      this.isGRlist = true;
      this.isGR = false;
      this.isMyComputer = false
      this.isKlaviyoList = false;
      this.isKlaviyo = false;
      } else {
        eventBus.trigger("notify", {
          notification: {
            group: "top-right",
            type: "warning",
            text: "Your GetResponse API Key is not valid",
          },
        });
        return false;
      }
    },
    onSelectList(event) {
      this.radioButton = document.getElementById(event.target.id);
      this.radioButtons.push(this.radioButton);
      if (this.radioButtons.length > 0) {
        this.radioButtons.forEach((item) => {
          if (item.checked) {
            item.checked = false;
          }
        });
      }

      this.radioButton.checked = true;
      this.klaviyoIdImport = event.target.id;
      this.klaviyoImportListName = event.target.name;
    },
    async getKlaviyoImport() {
      if (!this.klaviyoIdImport) {
        eventBus.trigger("notify", {
          notification: {
            group: "top-right",
            type: "warning",
            text: "Please select a list to import",
          },
        });
        return false;
      }
      this.isActive = true;
      let marker = undefined;
      let tempmarker = undefined;
      this.counter = 0;
      this.progress = 0;
      const data = await this.$alfredService.getKlaviyoListMembers({
        id: this.klaviyoIdImport,
        marker: marker,
      });
      if (data) {
        marker = data.marker || undefined;
        if (marker === undefined) {
          this.progress = 100;
        }
        let response = data;
        let content = response.records
          .map((x) => x.email)
          .filter((x) => x != "");
        if (marker != undefined) {
          while (
            marker != undefined &&
            marker != tempmarker &&
            this.counter < 500 &&
            this.isActive
          ) {
            this.counter += 1;
            tempmarker = marker;
            if (
              this.counter > 1 &&
              this.counter % 5 === 0 &&
              this.counter % 4 === 0
            ) {
              await this.sleep(10000);
            }

            //progressbar logic
            if (this.counter <= 100) {
              if (this.counter % 2 == 0) {
                this.progress = this.progress + 1;
              }
            } else if (this.counter <= 200) {
              if (this.counter % 5 == 0) {
                this.progress = this.progress + 1;
              }
            } else {
              if (this.counter % 10 == 0) {
                this.progress = this.progress + 1;
              }
            }

            const data1 = await this.$alfredService.getKlaviyoListMembers({
              id: this.klaviyoIdImport,
              marker: marker,
            });
            if (data1) {
              let additionalData = data1;
              this.isKlaviyoImporting = true;
              marker = additionalData.marker || undefined;
              content = content.concat(
                additionalData.records
                  .map((x) => x.email)
                  .filter((x) => x != "")
              );
            }
          }
          if (this.counter > 500) {
            eventBus.trigger("notify", {
              notification: {
                group: "top-right",
                text: `List is too large`,
                type: "error",
              },
            });
            return;
          }
          if (marker == undefined) {
            this.isUploading = true;
            this.progress = 100;
          }
          if (this.isActive) {
            this.createCSVFileObject(content);
          }
        } else {
          this.isUploading = true;
          this.isKlaviyoImporting = true;
          this.createCSVFileObject(content);
        }
      }
    },

    async getGRImport() {
      if (!this.klaviyoIdImport) {
        eventBus.trigger("notify", {
          notification: {
            group: "top-right",
            type: "warning",
            text: "Please select a list to import",
          },
        });
        return false;
      }
      this.isActive = true;
      let current_page = undefined;
      let total_pages = undefined
      this.counter = 0;
      this.progress = 0;
      const data = await this.$alfredService.getGRListMembers({
        id: this.klaviyoIdImport,
        current_page: current_page,
      });
      if (data) {
        current_page = data.current_page || undefined;
        total_pages = data.total_pages || undefined;
        if( current_page === undefined){
          this.progress = 100;
        }
        let response = data;
        let content = response.contacts
          .map((x) => x.email)
          .filter((x) => x != "");          
          if(current_page!=undefined && total_pages!=undefined) {            
            while (
              parseInt(current_page) < parseInt(total_pages) && this.counter < 500
          ) {  
            this.counter += 1;
            current_page = parseInt(current_page) + 1;   
            if (
              this.counter > 1 &&
              this.counter % 5 === 0 &&
              this.counter % 4 === 0
            ) {
              await this.sleep(10000);
            }
            if (this.counter <= 100) {
              if (this.counter % 2 == 0) {
                this.progress = this.progress + 1;
              }
            } else if (this.counter <= 200) {
              if (this.counter % 5 == 0) {
                this.progress = this.progress + 1;
              }
            } else {
              if (this.counter % 10 == 0) {
                this.progress = this.progress + 1;
              }
            }    
              const data1 = await this.$alfredService.getGRListMembers({
                id: this.klaviyoIdImport,
                current_page: current_page,
               });
              if (data1) {
              let additionalData = data1;
              this.isKlaviyoImporting = true;
              content = content.concat(
                additionalData.contacts
                  .map((x) => x.email)
                  .filter((x) => x != "")
              );
            }                         
          }
          if (this.counter > 500) {
            eventBus.trigger("notify", {
              notification: {
                group: "top-right",
                text: `List is too large`,
                type: "error",
              },
            });
            return;
          }
          if (this.isActive) {
            this.createCSVFileObject(content);
          }
          } else {
          this.isUploading = true;
          this.isKlaviyoImporting = true;
          this.createCSVFileObject(content);
        }          
      }
    },  


    createCSVFileObject(content) {
      if (content.length > 0) {
        const fileName = this.klaviyoImportListName + ".csv";
        const fileObj = new File(
          [new Blob([content.join("\n")], { type: "text/csv" })],
          fileName,
          {
            lastModified: new Date(),
            type: "text/csv",
          }
        );
        this.files = [fileObj];
        this.submit();
      } else {
        eventBus.trigger("notify", {
          notification: {
            group: "top-right",
            text: `List is empty`,
            type: "error",
          },
        });
        this.isUploading = false;
        this.isKlaviyoImporting = false;
        this.klaviyoIdImport = "";
      }
    },

    onFileInputChange(event) {
      if (event.target.files && this.validate(event.target.files)) {
        this.files = Array.from(event.target.files);
      }
    },
    validate(files) {
      if (!this.validateFilesLength(files)) {
        eventBus.trigger("notify", {
          notification: {
            group: "top-right",
            type: "error",
            text: "You can upload only one file!",
          },
        });
        return false;
      }
      const file = files[0];
      const fileValidators = [this.validateFileFormat];
      if (!fileValidators.every((validator) => validator(file))) {
        eventBus.trigger("notify", {
          notification: {
            group: "top-right",
            type: "warning",
            text: "Only CSV files are allowed.",
          },
        });
        return false;
      }
      return true;
    },
    validateFilesLength(files) {
      return files.length <= 1;
    },
    validateFileFormat(file) {
      const csvFileFormatRegEx = /(.*?)\.(txt|csv|xlsx|xls)$/;
      return file.name.match(csvFileFormatRegEx);
    },
    async submit() {
      if (this.files.length === 0) {
        return;
      }
      this.isUploading = true;
      const success = await this.uploadFile();
      if (success) {
        this.isOpen = false;
        this.isKlaviyoImporting = false;
      }
      this.isUploading = false;
      this.isKlaviyoImporting = false;
    },

    async onPageChange(page) {
      eventBus.trigger("update", { page });
    },
    resetPaginator() {
      this.$refs.paginator.setPage(1);
    },
    async uploadFile() {
      const file = this.files.pop();
      const formData = new FormData();
      formData.append("file", file);
      try {
        await this.$alfredServiceV2.uploadFile(formData);

        // const jobResponse = await this.$alfredServiceV2.createJob({
        //   input_file: {
        //     id: file["id"],
        //   },
        //   actions: ["evaluation"],
        // });
        // const jobId = jobResponse["id"];
        eventBus.trigger("notify", {
          notification: {
            group: "top-right",
            text: `File upload has successfully completed!`,
            type: "success",
          },
        });
        eventBus.trigger("update");
        this.isOpen = false;
        // await this.$router.push({
        //   name: "ListHealthCheck",
        //   params: {
        //     id: jobId,
        //   },
        // });
        // return true;
      } catch (e) {
        if (e?.response?.status === 400 && e?.response?.data?.file) {
          eventBus.trigger("notify", {
            notification: {
              group: "top-right",
              text: `${file.name}: ${e.response.data["file"][0]}`,
              type: "error",
            },
          });
        } else {
          eventBus.trigger("notify", {
            notification: {
              group: "top-right",
              text: `Unexpected error has occurred while uploading ${file.name} file!`,
              type: "error",
            },
          });
        }
      }
      return false;
    },
    // async searchList() {
    //   if (this.searchKeyword) {
    //     this.klaviyoList = await this.$alfredService.getKlaviyoList(
    //       this.searchKeyword.toLowerCase()
    //     );
    //   } else {
    //     this.klaviyoList = await this.$alfredService.getKlaviyoList();
    //   }
    // },
    async searchList() {
      this.klaviyoIdImport = "";
      if (this.radioButton) {
        this.radioButton.checked = false;
      }
      if (this.searchKeyword) {
        this.klaviyoList = this.tempklaviyoList.filter((x) =>
          x.list_name.toLowerCase().includes(this.searchKeyword.toLowerCase())
        );
      } else {
        this.klaviyoList = this.tempklaviyoList;
      }
      this.klaviyoList = _sortBy(this.klaviyoList, "list_name");
    },
    async searchGRList() {
      this.klaviyoIdImport = "";
      if (this.radioButton) {
        this.radioButton.checked = false;
      }
      if (this.searchKeyword) {
        this.GRList = this.tempklaviyoList.filter((x) =>
          x.list_name.toLowerCase().includes(this.searchKeyword.toLowerCase())
        );
      } else {
        this.GRList = this.tempklaviyoList;
      }
      this.GRList = _sortBy(this.GRList, "list_name");
    },
    sleep(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    cancelKlaviyoImport() {
      this.isActive = false;
      this.isKlaviyoImporting = false;
      this.klaviyoIdImport = "";
    },
    handleYotpoImport(){
      eventBus.trigger("update");
      this.isYotpoOption = false;
      this.closeModal();
    },
    handleIterableImport(){
      eventBus.trigger("update");
      this.isIterableOption = false;
      this.closeModal();
    },
    handleYotpoClick(message) {
      this.isYotpoOption = message;
    },
    handleIterableClick(message) {
      this.isIterableOption = message;
    }
  },
};
</script>
<style scoped>
.bg-blue-400 {
    --tw-bg-opacity: 1 !important;
    background-color: #54ace9 !important;
}
.scroll-style::-webkit-scrollbar {
  width: 8px;
  background-color: gainsboro;
  border-radius: 2px;
}
.scroll-style::-webkit-scrollbar-thumb {
  background: #888;
}
</style>
