<template>
  <DialogContainer :isOpen="isOpen" :onClose="onClose" :maxWidth="'4xl'">
    <template v-slot:content>
      <HealthDetails :jobData="jobData" :isExtended="false" />
    </template>
  </DialogContainer>
</template>

<script>
import DialogContainer from "@/components/common/DialogContainer";
import HealthDetails from "@/components/basic/common/HealthDetails";
import modalMixin from "@/mixins/modalMixin";
import { reactive, ref } from "vue";
import eventBus from "@/utils/eventBus";

export default {
  name: "EvaluatedDetailsModal",
  components: {
    DialogContainer,
    HealthDetails,
  },
  mixins: [modalMixin],
  beforeMount() {
    eventBus.on(this.closeModalEventName, this.onClose);
  },
  beforeUnmount() {
    eventBus.off(this.closeModalEventName, this.onClose);
  },
  setup() {
    const isOpen = ref(false);
    const jobData = reactive({});
    const modalEventName = "open-details";
    const closeModalEventName = "close-details";

    return { isOpen, jobData, modalEventName, closeModalEventName };
  },
  methods: {
    async openModal(job) {
      this.isOpen = true;
      this.jobData = job;
    },
    onClose() {
      this.isOpen = false;
    },
  },
};
</script>
<style scoped>
@media print {
  .position-print {
    display: block !important;
  }
  .bg-print {
    background-color: white !important;
    @apply h-full;
  }
}
</style>
